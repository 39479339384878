import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import me from "../assets/img/me.jpg";

function About() {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="flex flex-col h-full min-h-screen text-center md:text-left  max-w-7xl pt-20 px-10 mx-auto items-center"
    >
      <h3 className="uppercase tracking-[20px] text-white text-2xl mb-4 md:mb-20">
        {t("about")}
      </h3>
      <div className="flex flex-col md:flex-row items-center">
        <motion.img
          initial={{
            x: -200,
            opacity: 0,
          }}
          transition={{
            duration: 1.2,
          }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="mt-3 md:mt-0 flex-shrink-0 w-36 h-36 rounded-full object-cover md:rounded-lg md:w-64 md:h-[25rem] xl:w-[500px] xl:h-[600px]"
          src={me}
        />
        <div className="spac-y-10 px-0 md:px-10 mt-3">
          <h4 className="text-3xl md:text-4xl font-semibold mb-4">
            {t("backgorund_title1")}{" "}
            <span className="underline decoration-[#d135c4]">
              {t("backgorund_title2")}
            </span>{" "}
            {t("backgorund_title3")}
          </h4>
          <p className="text-sm md:text-lg">{t("backgorund_info")}</p>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
