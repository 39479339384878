import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectGet } from "../Interfaces/Project";
import rightArrow from "../assets/img/right-arrow.svg";
import leftArrow from "../assets/img/left-arrow.svg";
import { SocialIcon } from "react-social-icons";

const BASE_URL = process.env.REACT_APP_API_URL;
function Projects() {
  const { i18n, t } = useTranslation();
  const [projects, setProjects] = useState<ProjectGet[]>([]);
  const contentWrapper = useRef<HTMLDivElement>(null);
  const sideScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element!.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  useEffect(() => {
    const locale = i18n.language;
    fetch(`${BASE_URL}/home?lang=${locale}`)
      .then((response) => response.json())
      .then((data) => setProjects(data));
  }, [i18n.language]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="relative h-auto min-h-screen flex overflow-hidden flex-col text-left max-w-full justify-evenly mx-auto items-center z-0 "
    >
      <h3 className="uppercase tracking-[20px] text-white text-2xl pt-20">
        {t("projects")}
      </h3>

      <div
        ref={contentWrapper}
        className="relative w-full items-center h-full flex overflow-y-hidden overflow-x-scroll snap-x snap-mandatory z-20 scrollbar scrollbar-trek-grey-400/20 scrollbar-thumb-[#d135c4]/30"
      >
        {projects.map((project, i) => (
          <div
            key={i}
            className="w-screen flex-shrink-0 flex flex-col space-y-5 items-center justify-evenly p-10 md:p-20 h-full min-h-screen"
          >
            <motion.img
              key={project.images.name}
              initial={{ x: -300, opacity: 0 }}
              transition={{ duration: 1.2 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              src={`content/images/${project.images.name}`}
              alt="proj1"
              className="w-64 h-50 md:w-auto md:h-72"
            />
            <div className="space-y-10 px-0 md:px-10 max-w-6xl">
              <div className="text-center">
                <SocialIcon
                  url={project.projectLink}
                  target="blank"
                  fgColor="white"
                  bgColor="transparent"
                  className="h-16 w-16 border-2 rounded-full"
                ></SocialIcon>
              </div>
              <h4 className="text-4xl font-semibold text-center">
                {project.name}
              </h4>
              <p className="text-lg text-center">{project.description} </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full absolute top-50 justify-between items-center px-5 z-40">
        <div
          className="h-full bg-[#7479ee] p-2 hidden md:block"
          onClick={() =>
            sideScroll(contentWrapper.current, 25, 100, -window.innerWidth)
          }
        >
          <img
            src={leftArrow}
            alt="left-arrow"
            className="h-10 w-10 cursor-pointer z-20"
          />
        </div>
        <div
          className="h-full bg-[#7479ee] p-2 hidden md:block"
          onClick={() =>
            sideScroll(contentWrapper.current, 25, 100, window.innerWidth)
          }
        >
          <img
            src={rightArrow}
            alt="left-arrow"
            className="h-10 w-10 cursor-pointer z-20"
          />
        </div>
      </div>
      <div className="w-full absolute top-[30%] bg-[#d135c4]/10 left-0 h-[500px] -skew-y-12 z-[-10]" />
    </motion.div>
  );
}

export default Projects;
