import React from "react";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import BackgroundCircles from "./BackgroundCircles";
import logo from "../assets/img/logo.svg";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { i18n, t } = useTranslation();
  const [text] = useTypewriter({
    words:
      i18n.language === "en"
        ? [
            "Hi my name is Boris",
            " I am full stack developer",
            "and web designer",
          ]
        : [
            "Salut je m'appelle Boris",
            "Je suis développeur full stack",
            "et concepteur de sites web",
          ],
    loop: true,
    delaySpeed: 2000,
  });
  return (
    <div className="h-screen bg-[url(./assets/img/background.jpg)] bg-no-repeat bg-center bg-cover flex flex-col space-y-8 items-center justify-center text-center overflow-hidden">
      <BackgroundCircles />
      <img
        className="realtive rounded-full h-32 w-32 mx-auto object-cover"
        src={logo}
        alt="logo"
      />
      <div className="z-20">
        <h2 className="text-sm uppercase text-white pb-2 tracking-[15px]">
          {t("software_engineer")}
        </h2>
        <h1 className="text-5xl lg:text-6xl font-semibold px-10">
          <span className="mr-1">{text}</span>
          <Cursor cursorColor="#d135c4" />
        </h1>
        <div className="pt-5 space-x-2">
          <a href="#about">
            <button className="heroButton">{t("about")}</button>
          </a>
          <a href="#experience">
            <button className="heroButton">{t("experience")}</button>
          </a>
          <a href="#skills">
            <button className="heroButton">{t("skills")}</button>
          </a>
          <a href="#projects">
            <button className="heroButton">{t("projects")}</button>
          </a>
        </div>
      </div>
    </div>
  );
}
