import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  ProjectsContext,
  ProjectsContextType,
} from "../context/projects-context";
import { Project } from "../Interfaces/Project";

const BASE_URL = process.env.REACT_APP_API_URL;
function AdminPage() {
  const [projects, setProjects] = useState<Project[]>([]);
  const { setPorjects } = React.useContext(
    ProjectsContext
  ) as ProjectsContextType;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(`${BASE_URL}/Admin`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProjects(data);
        setPorjects(data);
      });
  }, []);

  function createEdit(projectId?: string) {
    navigate({
      pathname: "/create-edit-project",
      search: projectId ? createSearchParams({ id: projectId }).toString() : "",
    });
  }

  function deleteProject(id: string) {
    const token = localStorage.getItem("token");
    fetch(`${BASE_URL}/admin/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const projs = projects.filter((p) => p.id !== data.id);
        setProjects(projs);
        setPorjects(projs);
      });
  }

  function publishProject(id: string) {
    const token = localStorage.getItem("token");
    fetch(`${BASE_URL}/Admin/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data: Project) => {
        const projs: Project[] = projects.map((p) =>
          p.id === data.id ? { ...p, ...{ showOnMain: data.showOnMain } } : p
        );
        setProjects(projs);
        setPorjects(projs);
      });
  }

  return (
    <>
      <div className="h-full">
        <h2 className="text-2xl uppercase text-center font-medium mt-4">
          Projects
        </h2>
        <div className="my-4 flex justify-end">
          <button
            className="bg-[#7479ee] py-2 px-10 text-white rounded-md font-bold"
            onClick={() => createEdit()}
          >
            Add project
          </button>
        </div>
        {projects.map((project, i) => (
          <div
            key={i}
            className="snap-center flex justify-evenly border-[1px] p-3 my-2"
          >
            <img
              src={require(`/public/content/images/${project.images.name}`)}
              alt="proj1"
              className="w-aut h-32"
            />
            <div className="space-y-2 px-0 md:px-10">
              <h4 className="text-4xl font-semibold text-center">
                {project.name}
              </h4>
              <div>
                <span>En description</span>
                <p className="text-lg text-center md:text-left">
                  {project.descriptionEn}{" "}
                </p>
              </div>
              <div>
                <span>Fr description</span>
                <p className="text-lg text-center md:text-left">
                  {project.descriptionFr}{" "}
                </p>
              </div>
              <div>
                <div>Link</div>
                {project.projectLink}
              </div>
            </div>
            <div>
              <div>Category</div>
              {project.category}
            </div>
            <div>
              <div>Action</div>
              <div className="flex flex-col space-y-1">
                <button
                  className="py-2 bg-green-500 px-10 text-white rounded-md"
                  onClick={() => createEdit(project.id)}
                >
                  Edit project
                </button>
                <button
                  className="bg-orange-500 py-2 px-10 text-white rounded-md"
                  onClick={() => publishProject(project.id)}
                >
                  {project.showOnMain ? "Publish" : "Unpublish"}
                </button>
                <button
                  className="py-2 bg-red-500  px-10 text-white rounded-md"
                  onClick={() => deleteProject(project.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AdminPage;
