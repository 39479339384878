import { motion } from "framer-motion";
import React from "react";
import Experience from "../Interfaces/Ecperience";

type Props = {
  exp: Experience;
};

function ExperienceCard({ exp }: Props) {
  return (
    <article className="flex flex-col md:flex-row rounded-lg items-center space-y-7 flex-shrink-0 w-[500px] md:w-[600px] xl:w-[900px] snap-center bg-[#292929] p-3 md:p-10 hover:opacity-100 opacity-40 cursor-pointer transition-opacity duration-200 overflow-hidden">
      <motion.img
        initial={{ x: -100, opacity: 0 }}
        transition={{ duration: 1.2 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="w-20 h-20 md:h-32 md:w-32 rounded-full md:rounded-full xl:w-[200px] xl:h-[200px] object-cover object-center"
        src={require(`../assets/img/${exp.logoName}`)}
        alt={exp.companyName}
      />
      <div className="px-0 md:px-10">
        <h4 className="text-4lx font-light mt-1">{exp.position}</h4>
        <p className="font-bold text-2xl mt-1">{exp.companyName}</p>
        <div className="flex space-x-2 my-2">
          {exp.techStack.map((tech) => {
            return (
              <img
                key={tech}
                src={require(`../assets/img/${tech}`)}
                alt="angular"
                className="h-10 w-10 rounded-full"
              />
            );
          })}
        </div>
        <p className="uppercase py-5 text-gray-300">
          Started work {exp.startYear} - Ended {exp.endYear}
        </p>
        <ul className="list-disc space-y-1 md:spae-y-5 ml-5 text-lg">
          {exp.keyPoints.map((points) => {
            return <li key={points}>{points}</li>;
          })}
        </ul>
      </div>
    </article>
  );
}

export default ExperienceCard;
