import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContextProv } from "../Interfaces/AuthContextProv";

const AuthContext = createContext<AuthContextProv>({
  token: "",
  isLoggedIn: false,
  login: (token: string) => {},
  logout: () => {},
});

export const AuthContextProvider = (props: any) => {
  const initToken =
    typeof window === "undefined" ? "" : localStorage.getItem("token");

  const [token, setToken] = useState<string | null>(initToken);

  const userIsLogedIn = !!token;
  const loginHandler = (token: string) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const logoutHandler = () => {
    setToken(null);
    window.localStorage.removeItem("token");
  };

  const ContextValue: AuthContextProv = {
    token: token,
    isLoggedIn: userIsLogedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={ContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
export const AuthVerify = (props: any) => {
  let location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("toke") || "");

    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  }, [location, props]);

  return;
};

export default AuthContext;
