import React, { useState } from "react";
import { Project } from "../Interfaces/Project";
type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export type ProjectsContextType = {
  projcts: Project[];
  getProjectById: (id: string) => Project | undefined;
  setPorjects: (projects: Project[]) => void;
};

export const ProjectsContext = React.createContext<ProjectsContextType | null>(
  null
);

export const ProjectsProvider: React.FC<Props> = ({ children }: Props) => {
  const [projcts, setProjects] = useState<Project[]>([]);

  const getProjectById = (id: string) => projcts.find((m) => m.id === id);
  const setPorjects = (projects: Project[]) => setProjects(projects);
  return (
    <ProjectsContext.Provider value={{ projcts, getProjectById, setPorjects }}>
      {children}
    </ProjectsContext.Provider>
  );
};
