import React, { useState } from "react";
import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

type Inputs = {
  name: string;
  email: string;
  subject: string;
  message: string;
  token: string;
};
const BASE_URL = process.env.REACT_APP_API_URL;
const ContactMe = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    executeRecaptcha("email").then((token) =>
      submitEnquiryForm(token, formData)
    );
  };

  const submitEnquiryForm = (token: string, formData: Inputs) => {
    formData.token = token;
    fetch(`${BASE_URL}/home`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      setSubmitted(true);
      reset();
      hideMeesage();
    });
  };
  function hideMeesage() {
    setTimeout(() => {
      setSubmitted(false);
    }, 2000);
  }
  return (
    <div className="relative h-full min-h-screen bg-[url(./assets/img/world-bg.svg)] bg-no-repeat bg-center bg-[length:70%_70%] flex text flex-col text-center md:text-left max-w-7xl pt-20 pb-10 justify-evenly mx-auto items-center">
      <h3 className="uppercase tracking-[20px] text-white text-2xl items-center">
        {t("contact")}
      </h3>
      <h4 className="text-2xl md:text-4xl font-semibold text-center mt-20 md:mt-0 mb-3 mb:mb-0">
        {t("contact_call")}{" "}
        <span className="underline decoration-[#7479ee]">
          {t("contact_call1")}
        </span>
      </h4>
      <div className="flex flex-col space-y-10">
        <div className="space-y-1 md:space-y-10 text-left">
          <div className="flex items-center space-x-5 justify-center">
            <ChatBubbleLeftIcon className="text-[#7479ee] h-7 w-7 animate-pulse" />
            <a
              href="https://teams.microsoft.com/l/chat/0/0?users=bkashirin@outlook.com"
              target="_blank"
              className="text-md md:text-2xl"
              rel="noreferrer"
            >
              {t("chat_now")}
            </a>
          </div>
          <div className="flex items-center space-x-5 justify-center">
            <EnvelopeIcon className="text-[#7479ee] h-7 w-7 animate-pulse" />
            <p className="text-md md:text-2xl">hello@jsaura.com</p>
          </div>
          <div className="flex items-center space-x-5 justify-center">
            <MapPinIcon className="text-[#7479ee] h-7 w-7 animate-pulse" />
            <p className="text-md md:text-2xl">Canada, Montreal</p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-2 w-fit mx-auto"
        >
          {submitted && (
            <span className="mt-0 p-0 text-green-500 w-100 text-center">
              {t("message_success")}
            </span>
          )}
          <div className="flex space-y-2 md:space-y-0 md:space-x-2 flex-col md:flex-row">
            <div className="flex flex-col">
              <input
                {...register("name", { required: true })}
                placeholder={t("name") || ""}
                className="contactInput"
                type="text"
              />
              <div className="text-sm text-red-500">
                {errors.name && <span>This field is required</span>}
              </div>
            </div>
            <div className="flex flex-col">
              <input
                {...register("email", { required: true })}
                placeholder={t("email") || ""}
                className="contactInput"
                type="email"
              />
              <div className="text-sm text-red-500">
                {errors.email && <span>This field is required</span>}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <input
              {...register("subject", { required: true })}
              placeholder={t("subject") || ""}
              className="contactInput"
              type="text"
            />
            <div className="text-sm text-red-500">
              {errors.subject && <span>This field is required</span>}
            </div>
          </div>
          <div className="flex flex-col">
            <textarea
              {...register("message", { required: true })}
              placeholder={t("message") || ""}
              className="contactInput"
            ></textarea>
            <div className="text-sm text-red-500">
              {errors.subject && <span>This field is required</span>}
            </div>
          </div>
          <button
            disabled={submitted}
            type="submit"
            className="bg-[#7479ee] py-5 px-10 text-white rounded-md font-bold text-lg"
          >
            {t("submit")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;
