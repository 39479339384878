import React, { useRef } from "react";
import { motion } from "framer-motion";
import ExperienceCard from "./ExperienceCard";
import Experience from "../Interfaces/Ecperience";
import { useTranslation } from "react-i18next";
import rightArrow from "../assets/img/right-arrow.svg";
import leftArrow from "../assets/img/left-arrow.svg";

function WorkExperience() {
  const { i18n, t } = useTranslation();
  const contentWrapper = useRef<HTMLDivElement>(null);
  const sideScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element!.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const experiences: Experience[] =
    i18n.language === "en"
      ? [
          {
            logoName: "biron.jpg",
            position: "Senior Frontend Developer",
            companyName: "Biron",
            techStack: ["angular.svg", "net.svg"],
            startYear: "2021",
            endYear: "Current",
            keyPoints: [
              "Projects architecture",
              "Front end development",
              "Back end development",
            ],
          },
          {
            logoName: "agco.jpg",
            position: "Développeur Full Stack",
            companyName: "Senior Web Developer",
            techStack: ["angular.svg", "cplus.svg", "websocket.svg"],
            startYear: "2017",
            endYear: "2021",
            keyPoints: [
              "Embedded solution implementation",
              "Front end development",
              "Back end development",
            ],
          },
          {
            logoName: "routeworthy.jpg",
            position: "Full Stack Developer",
            companyName: "Routeworthy",
            techStack: ["angular.svg", "net.svg"],
            startYear: "2016",
            endYear: "2017",
            keyPoints: [
              "Complex solution implementation",
              "Front end development",
              "Back end development",
            ],
          },
          {
            logoName: "gemstelecom.jpg",
            position: "Senior Web Developer",
            companyName: "(Gemstelecom) Cronomagic",
            techStack: ["react.svg", "net.svg"],
            startYear: "2016",
            endYear: "2017",
            keyPoints: [
              "Development ERP, CRM",
              "Front end development",
              "Back end development",
            ],
          },
        ]
      : [
          {
            logoName: "biron.jpg",
            position: "Développeur Frontend Sénior",
            companyName: "Biron",
            techStack: ["angular.svg", "net.svg"],
            startYear: "2021",
            endYear: "Actuel",
            keyPoints: [
              "Architecture des projets",
              "Développement front-end",
              "Développement back-end",
            ],
          },

          {
            logoName: "agco.jpg",
            position: "Full Stack Developer",
            companyName: "Développeur senior",
            techStack: ["angular.svg", "cplus.svg", "websocket.svg"],
            startYear: "2017",
            endYear: "2021",
            keyPoints: [
              "Implémentation de la solution embarquée",
              "Développement front-end",
              "Développement back-end",
            ],
          },
          {
            logoName: "routeworthy.jpg",
            position: "Développeur Full Stack",
            companyName: "Routeworthy",
            techStack: ["angular.svg", "net.svg"],
            startYear: "2016",
            endYear: "2017",
            keyPoints: [
              "Implémentation de solutions complexes",
              "Développement front-end",
              "Développement back-end",
            ],
          },
          {
            logoName: "gemstelecom.jpg",
            position: "Développeur Web Sénior",
            companyName: "(Gemstelecom) Cronomagic",
            techStack: ["react.svg", "net.svg"],
            startYear: "2016",
            endYear: "2017",
            keyPoints: [
              "Développement ERP, CRM",
              "Développement front-end",
              "Développement back-end",
            ],
          },
        ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="h-full min-h-screen flex relative overflow-hidden flex-col text-left max-w-full px-5 mx-auto items-center pt-20"
    >
      <h3 className="top-24 uppercase tracking-[20px] text-white text-2xl items-center">
        {t("experience")}
      </h3>
      <div className="flex w-full items-center">
        <div
          className="h-full bg-[#7479ee] p-2 hidden md:block"
          onClick={() => sideScroll(contentWrapper.current, 25, 100, -450)}
        >
          <img
            src={leftArrow}
            alt="left-arrow"
            className="h-10 w-10 cursor-pointer z-20 left-2"
          />
        </div>
        <div
          ref={contentWrapper}
          className="w-full h-full flex items-center space-x-5 overflow-x-scroll overflow-y-hidden p-10 snap-mandatory scrollbar scrollbar-trek-grey-400/20 scrollbar-thumb-[#d135c4]/30"
        >
          {experiences.map((exp) => (
            <ExperienceCard exp={exp} key={exp.companyName} />
          ))}
        </div>
        <div
          className="h-full bg-[#7479ee] p-2 hidden md:block"
          onClick={() => sideScroll(contentWrapper.current, 25, 100, 450)}
        >
          <img
            src={rightArrow}
            alt="left-arrow"
            className="h-10 w-10 cursor-pointer z-20 "
          />
        </div>
      </div>
    </motion.div>
  );
}

export default WorkExperience;
