import React, { useContext } from "react";
import logo from "../assets/img/logo.svg";
import AuthContext from "../auth/AuthProvider";

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

const AdminLayout: React.FC<Props> = ({ children }: Props) => {
  const authCtx = useContext(AuthContext);
  const logout = () => {
    if (authCtx.logout) authCtx.logout();
  };
  return (
    <div className="h-full min-h-screen flex flex-col w-full text-gray-50  bg-black ">
      <nav className="flex justify-between bg-black p-4 w-full border-b-2 border-b-violet-500">
        <img
          className="realtive rounded-full h-10 w-10 object-cover"
          src={logo}
          alt="logo"
        />
        <button
          className="bg-[#7479ee] py-2 px-10 text-white rounded-md font-bold "
          onClick={logout}
        >
          Log out
        </button>
      </nav>
      <main className="h-full">{children}</main>
    </div>
  );
};

export default AdminLayout;
