import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  ProjectsContext,
  ProjectsContextType,
} from "../context/projects-context";
import { Project } from "../Interfaces/Project";
type Props = {};

const CreateEditProject = (props: Props) => {
  const [searchparams] = useSearchParams();
  const [project, setProject] = useState<Project>();
  const [preview, setPreview] = useState<string>();
  const [img, setImg] = useState<File>();
  const projectId = searchparams.get("id");
  const { getProjectById } = React.useContext(
    ProjectsContext
  ) as ProjectsContextType;

  const BASE_URL = process.env.REACT_APP_API_URL;
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Project>();

  const categories: { key: string; value: number }[] = [
    { key: "web", value: 0 },
    { key: "designe", value: 1 },
    { key: "moblie", value: 2 },
  ];

  useEffect(() => {
    if (projectId) {
      const proj = getProjectById(projectId);
      if (proj) {
        fetch(`/content/images/${proj.images.name}`)
          .then((f) => {
            return f.blob();
          })
          .then((f) => {
            setImg(new File([f], proj.images.name));
            setProject(proj);
          });
        setValue("name", project?.name || "");
        setValue("descriptionEn", project?.descriptionEn || "");
        setValue("descriptionFr", project?.descriptionFr || "");
        setValue("projectLink", project?.projectLink || "");
        setValue("showOnMain", project?.showOnMain || false);
        setValue("category", project?.category!);
      }
    }
  }, [project, getProjectById, projectId]);

  const watchImg = watch("images.name");

  useEffect(() => {
    if (watchImg) {
      const img = watchImg as unknown;
      const fileList = img as FileList;
      if (fileList.length > 0) setPreview(URL.createObjectURL(fileList[0]));
    }
  }, [watchImg]);

  function handleClick(e: any) {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setValue("images.name", e.target.files[0]);
  }

  const onSubmit: SubmitHandler<Project> = (formData) => {
    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("descriptionEn", formData.descriptionEn);
    formdata.append("descriptionFr", formData.descriptionFr);
    formdata.append("category", formData.category.toString());
    formdata.append("showOnMain", formData.showOnMain.toString());
    formdata.append("projectLink", formData.projectLink);
    const token = localStorage.getItem("token");
    if (!project?.id) {
      formdata.append("images", formData.images.name);
      fetch(`${BASE_URL}/Admin`, {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      }).then((r) => console.log(r));
    } else {
      formdata.append("id", project.id);
      formdata.append("images", img!);
      fetch(`${BASE_URL}/Admin`, {
        method: "Put",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      }).then((r) => console.log(r));
    }
  };
  return (
    <>
      <h2 className="text-2xl uppercase text-center font-medium mt-4">
        Create edit Project
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-2 mx-auto px-5 w-4/6"
      >
        {!preview && project?.images.name && (
          <img
            className="my-3"
            src={require(`/public/content/images/${project.images.name}`)}
            alt="img"
          />
        )}
        {preview && <img className="my-3" src={preview} alt="img" />}
        <label
          htmlFor="my-file"
          className="text-center bg-[#7479ee] py-3 px-10 text-white rounded-md font-bold text-lg"
        >
          Attache image
        </label>
        <input
          {...register("images.name")}
          multiple={false}
          accept="png"
          className="hidden"
          type="file"
          name="file"
          id="my-file"
          onChange={handleClick}
        />
        <div className="flex w-full justify-between">
          <input
            placeholder="Project name"
            type="text"
            {...register("name", { required: true })}
            className="contactInput w-[48%]"
          />
          <input
            placeholder="Project link"
            type="text"
            {...register("projectLink", { required: true })}
            className="contactInput w-[48%]"
          />
        </div>
        <div className="flex w-full justify-between">
          <textarea
            placeholder="Description en"
            {...register("descriptionEn", { required: true })}
            className="contactInput w-[48%]"
          />
          <textarea
            placeholder="Description fr"
            {...register("descriptionFr", { required: true })}
            className="contactInput w-[48%]"
          />
        </div>
        <div className="flex w-full justify-between">
          <label className="w-[48%] my-5">
            Show on Main
            <input
              type="checkbox"
              {...register("showOnMain")}
              className="ml-5"
            />
          </label>
          <select className="contactInput w-[48%]" {...register("category")}>
            {categories.map((cat) => (
              <option value={cat.value} key={cat.key}>
                {cat.key}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className="bg-[#7479ee] py-5 px-10 text-white rounded-md font-bold text-lg"
        >
          Save
        </button>
      </form>
    </>
  );
};

export default CreateEditProject;
