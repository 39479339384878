import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./i18n";
import { AuthContextProvider } from "./auth/AuthProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import RequireAuth from "./auth/GuardedRoute";
import AdminPage from "./pages/AdminPage";
import CreateEditProject from "./pages/CreateEditProject";
import AdminLayout from "./components/AdminLayout";
import { ProjectsProvider } from "./context/projects-context";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="login" element={<Login />} />

            <Route
              path="admin"
              element={
                <RequireAuth>
                  <ProjectsProvider>
                    <AdminLayout>
                      <AdminPage />
                    </AdminLayout>
                  </ProjectsProvider>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="create-edit-project"
              element={
                <RequireAuth>
                  <ProjectsProvider>
                    <AdminLayout>
                      <CreateEditProject />
                    </AdminLayout>
                  </ProjectsProvider>
                </RequireAuth>
              }
            ></Route>
          </Routes>
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
