import React from "react";
import { motion } from "framer-motion";

type Props = {
  directionLeft?: boolean;
  skills: { icon: string; knowledge: string };
};

function Skill({ directionLeft, skills }: Props) {
  return (
    <div className="group relative flex cursor-pointer">
      <motion.img
        initial={{
          opacity: 0,
          y: directionLeft ? -250 : 250,
        }}
        transition={{ duration: 0.5 }}
        whileInView={{ opacity: 1, y: 0 }}
        src={require(`../assets/img/${skills.icon}`)}
        alt={skills.icon}
        className="rounded-full border border-gray-500 object-none w-16 h-16 md:w-28 md:h-28 xl:w-32 xl:h-32 filter group-hover:grayscale transition duration-300 ease-in-out"
      />
      <div className="absolute opacity-0 group-hover:opacity-95 transition duration-300 easy-in-out group-hover:bg-[#7479ee] h-16 w-16 md:w-28 md:h-28 xl:w-32 xl:h-32 rounded-full z-0">
        <div className="flex items-center justify-center h-full">
          <p className="text-xl md:text-2xl font-bold text-white opacity-100">
            {skills.knowledge}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Skill;
