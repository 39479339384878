import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../auth/AuthProvider";

const BASE_URL = process.env.REACT_APP_API_URL;
function Login() {
  const userRef = useRef<any>();
  const errRef = useRef<any>();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/account `, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
        body: JSON.stringify({ email: user, password: pwd }),
      })
        .then((m: any) => m.json())
        .then((response) => response);

      const accessToken = response.token as string;
      const name = response?.name;
      if (authCtx.login) authCtx.login(accessToken);
      setSuccess(true);
      navigate("/admin");
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <section className="h-screen bg-[rgb(1,1,17)] flex justify-center items-center ">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <div className="flex flex-col">
          <h3 className="uppercase tracking-[20px] text-white text-2xl items-center mb-10 text-center">
            Login
          </h3>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-2 w-fit mx-auto"
          >
            <input
              className="contactInput"
              placeholder="Login"
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <input
              className="contactInput"
              placeholder="Password"
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <button className="bg-[#7479ee] py-5 px-10 text-white rounded-md font-bold text-lg">
              Log In
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Login;
