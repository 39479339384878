import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import Skill from "./Skill";

const skills: Array<{ icon: string; knowledge: string }> = [
  { icon: "angular.svg", knowledge: "100%" },
  { icon: "react.svg", knowledge: "75%" },
  { icon: "node-js.svg", knowledge: "65%" },
  { icon: "vuejs.svg", knowledge: "75%" },
  { icon: "net_nobg.svg", knowledge: "85%" },
  { icon: "nextjs.svg", knowledge: "75%" },
  { icon: "js.svg", knowledge: "85%" },
  { icon: "db.svg", knowledge: "60%" },
];

function Skills() {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      className="realtivre h-screen min-h-screen pt-20 justify-start flex bg-[url(./assets/img/bg_lotus.svg)] bg-no-repeat bg-center bg-[length:80%_80%] relative flex-col text-center md:text-left max-w-[2000px] xl:px-10 xl:space-y-0 mx-auto items-center"
    >
      <div className="text-center flex flex-col mb-10">
        <h3 className="top-24 mb-3 uppercase tracking-[20px] text-white text-2xl items-center">
          {t("skills")}
        </h3>
        <h3 className="top-36 uppercase tracking-[3px] text-white text-sm ">
          {t("skill_subtitle")}
        </h3>
      </div>
      <div className="h-full flex items-center -mt-28">
        <div className="grid grid-cols-4 gap-5">
          {skills.map((skill, i) => (
            <Skill directionLeft={i % 2 === 0} skills={skill} key={i} />
          ))}
        </div>
      </div>
    </motion.div>
  );
}

export default Skills;
