import React from "react";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { i18n, t } = useTranslation();
  const handleLanguage = () => {
    const lang = i18n.language === "en" ? "fr" : "en";
    i18n.changeLanguage(lang);
  };
  return (
    <header className="absolute  w-full top-0 p-5 flex flex-center justify-between  mx-auto z-20 items-center">
      <motion.div
        initial={{
          x: -500,
          opacity: 0,
          scale: 0.5,
        }}
        animate={{
          x: 0,
          opacity: 1,
          scale: 1,
        }}
        transition={{
          duration: 1.5,
        }}
        className="flex flex-row items-center"
      >
        <SocialIcon
          url="https://www.linkedin.com/in/boris-kashirin-raina-9b57825"
          fgColor="white"
          bgColor="transparent"
        />
        <SocialIcon
          url="https://www.facebook.com/JsAura-105893252223059"
          fgColor="white"
          bgColor="transparent"
        />
        <SocialIcon
          url="https://twitter.com/"
          fgColor="white"
          bgColor="transparent"
        />
      </motion.div>
      <motion.div
        initial={{
          x: 500,
          opacity: 0,
          scale: 0.5,
        }}
        animate={{
          x: 0,
          opacity: 1,
          scale: 1,
        }}
        transition={{
          duration: 1.5,
        }}
        className=" flex flex-row items-center text-white cursor-pointer"
      >
        <span onClick={handleLanguage}>
          {i18n.language === "en" ? "FR" : "EN"}
        </span>
        <SocialIcon
          className="cursor-pointer"
          network="email"
          fgColor="white"
          bgColor="transparent"
          url="#contact"
        />
        <a
          className="uppecase hidden md:inline-flex text-sm text-white uppercase animate-pulse"
          href="#contact"
        >
          {t("get_intouch")}
        </a>
      </motion.div>
    </header>
  );
}
